import {
  Container,
  Grid,
  Box,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { fromEvent } from 'rxjs';
import { grey } from '@material-ui/core/colors';
import { debounceTime } from 'rxjs/operators';
import { useApolloClient } from '@apollo/client';
import sg, { GET_INDEXPAGE, REC_VISIT } from '../../serviceGlobal';
import PopupWin from '../../widgets/PopupWin';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  img: {
    cursor: 'pointer'
  },
  galleryCardN: {
    border: '1px solid red'
  },
  galleryCard: {
    '&::before': {
      content: '""',
      float: 'left',
      display: 'block',
      paddingTop: '100%'
    },
    '&::after': {
      content: '""',
      display: 'block',
      clear: 'both'
    }
  },
  pic: {
    width: '100%',
    objectFit: 'cover'
  }
}));
export default () => {
  const classes = useStyles();
  const [imgLst, setImgLst] = useState([]);
  const [open, setOpen] = useState(false);
  const [visitTimes, setVisitTimes] = useState('');
  const [currentPic, setCurrentPic] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const routeParams = useParams();
  const { idx: pageIdx = 79 } = routeParams;
  const isLapTop = useMediaQuery(theme.breakpoints.up('lg'));
  const selfDom = useRef(null);
  const scrollListener = useRef(null);
  const client = useApolloClient();
  const doOnScroll = () => {
    const scrollTop = selfDom.current ? selfDom.current.parentNode.scrollTop : 0;
    localStorage.setItem('pageScrollTop', scrollTop);
  };
  useEffect(() => {
    scrollListener.current = fromEvent(selfDom.current.parentNode, 'scroll')
      .pipe(debounceTime(100)).subscribe(doOnScroll);
    return () => {
      scrollListener.current.unsubscribe();
    };
  }, []);
  useEffect(() => {
    const ob = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          ob.unobserve(img);
        }
      });
    }, {
      root: null,
      threshold: 0,
    });
    const imgs = selfDom.current.querySelectorAll('img');
    imgs.forEach((img) => {
      ob.observe(img);
    });
  }, [imgLst]);
  useEffect(() => {
    client.query({ query: GET_INDEXPAGE, variables: { pageId: pageIdx } }).then((retObj) => {
      const imgArray = [];
      if (retObj.data?.page) {
        const { content } = retObj.data.page;
        const imgMatchObj = content.match(/(<img .*?>)(<figcaption>.*?<\/figcaption>)?/ig);
        if (imgMatchObj) {
          let idx = 0;
          imgMatchObj.forEach((img) => {
            idx++;
            const dataId = img.replace(/<img.*?data-id\s*?=\s*?["'](\d+)["'].*?\/>(<figcaption>.*?<\/figcaption>)?/i, '$1');
            let src = img.replace(/<img.*?src\s*?=\s*?["'](.*?)["'].*?\/>(<figcaption>.*?<\/figcaption>)?/i, '$1');
            const figCaption = img.replace(/<img.*?src\s*?=\s*?["'](.*?)["'].*?\/>(<figcaption>(.*?)<\/figcaption>)?/i, '$3');
            src = src.replace(/(\/[A-Za-z-]+0?)1(\.)/, '$12$2');
            let jpg = '';
            const jpgMatch = /\/([A-Za-z0-9-]+.jpg$)/i.exec(src);
            if (jpgMatch && jpgMatch[1]) {
              let whole;
              [whole, jpg] = jpgMatch;
              sg.mute(whole);
            }
            const srcVal = src.replace('http:', sg.httpVal);
            const imgItem = {
              idx,
              jpg,
              dataId,
              figCaption,
              src: srcVal
            };
            imgArray.push(imgItem);
            sg.mute(imgItem);
          });
        }
      } else {
        Array(20).fill('./static/images/54.jpg').forEach((val, index) => {
          const imgItem = {
            idx: index + 1,
            jpg: '54.jpg',
            dataId: index,
            src: val
          };
          imgArray.push(imgItem);
        });
      }
      setImgLst(imgArray);
      const previousTop = localStorage.getItem('pageScrollTop')
        ? localStorage.getItem('pageScrollTop') * 1 : 0;
      selfDom.current.parentNode.scrollTop = previousTop;
    }).catch((err) => { console.log(err); });
    client.mutate({
      mutation: REC_VISIT,
      variables: {
        val: pageIdx * 1,
      },
    }).then((ret) => {
      const times = ret.data?.recordVisit;
      setVisitTimes(times);
    });
    /*
    const paramObj = {
      json: 'get_page',
      id: pageIdx,
      post_type: 'page'
    };
    sg.get(sg.apiUrl, paramObj).subscribe((retObj) => {
      const imgArray = [];
      if (retObj.page) {
        const { content } = retObj.page;
        const imgMatchObj = content.match(/(<img .*?>)(<figcaption>.*?<\/figcaption>)?/ig);
        if (imgMatchObj) {
          let idx = 0;
          imgMatchObj.forEach((img) => {
            idx++;
            const dataId = img.replace(/<img.*?data-id\s*?=\s*?["'](\d+)["'].*?\/>(<figcaption>.*?<\/figcaption>)?/i, '$1');
            let src = img.replace(/<img.*?src\s*?=\s*?["'](.*?)["'].*?\/>(<figcaption>.*?<\/figcaption>)?/i, '$1');
            const figCaption = img.replace(/<img.*?src\s*?=\s*?["'](.*?)["'].*?\/>(<figcaption>(.*?)<\/figcaption>)?/i, '$3');
            src = src.replace(/(\/[A-Za-z-]+0?)1(\.)/, '$12$2');
            let jpg = '';
            const jpgMatch = /\/([A-Za-z0-9-]+.jpg$)/i.exec(src);
            if (jpgMatch && jpgMatch[1]) {
              let whole;
              [whole, jpg] = jpgMatch;
              sg.mute(whole);
            }
            const srcVal = src.replace('http:', sg.httpVal);
            const imgItem = {
              idx,
              jpg,
              dataId,
              figCaption,
              src: srcVal
            };
            imgArray.push(imgItem);
            sg.mute(imgItem);
          });
        }
      } else {
        Array(20).fill('./static/images/54.jpg').forEach((val, index) => {
          const imgItem = {
            idx: index + 1,
            jpg: '54.jpg',
            dataId: index,
            src: val
          };
          imgArray.push(imgItem);
        });
      }
      setImgLst(imgArray);
      const previousTop = localStorage.getItem('pageScrollTop')
        ? localStorage.getItem('pageScrollTop') * 1 : 0;
      selfDom.current.parentNode.scrollTop = previousTop;
    });
    */
  }, [pageIdx]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const imgCardClick = (evt) => {
    scrollListener.current.unsubscribe();
    const { currentTarget: triggeredObj } = evt;
    const imgIdx = triggeredObj.getAttribute('img_idx') * 1;
    const imgItem = imgLst.find((img) => img.idx === imgIdx);
    setCurrentPic(imgItem);
    handleClickOpen();
    sg.mute(isLapTop);
    navigate(`/page/detail/${pageIdx}/${imgItem.dataId}`, { replace: false });
  };
  sg.mute(open, currentPic, handleClose);
  return (
    <Box
      className={classes.root}
      title="Painting"
      ref={selfDom}
    >
      <Box
        sx={{
          position: 'fixed',
          bottom: 15,
          zIndex: 10000,
          left: 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            }
          },
          animation: 'fadeIn 5s ease',
        }}
      >
        <Typography
          sx={{
            borderRadius: '50%',
            pl: 0.5,
            pr: 0.5,
            border: {
              xl: 'none',
              xs: `1px solid ${grey.A200}`,
            },
            textShadow: '2px 2px 4px #000000',
            color: grey[100]
          }}
        >
          {visitTimes}
        </Typography>
      </Box>
      <Container
        maxWidth={false}
      >
        <Box>
          <Grid
            container
            spacing={3}
          >
            {imgLst.map((img) => (
              <Grid
                item
                key={img.idx}
                xl={3}
                lg={4}
                md={6}
                xs={12}
              >
                <Box
                  className={clsx('', 'picFrame')}
                  onClick={imgCardClick}
                  img_idx={img.idx}
                  sx={{
                    position: 'relative'
                  }}
                >
                  <img
                    className={clsx(classes.img, classes.pic)}
                    style={{
                      display: 'block'
                    }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    data-src={img.src}
                    onContextMenu={(e) => e.preventDefault()}
                    alt=""
                    title="Yoyo work"
                  />
                  {img.figCaption !== '' && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: 0,
                      color: 'white',
                      background: 'rgba(0,0,0,0.2)',
                      textAlign: 'center'
                    }}
                  >
                    <Typography>
                      {img.figCaption}
                    </Typography>
                  </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Hidden
        lgUp
      >
        <PopupWin
          onClose={handleClose}
          open={open}
          dialogTitle={currentPic.jpg}
        >
          <Box
            onClick={handleClose}
            sx={{
              display: 'fex',
              m: 0,
              p: 0,
              justifyContent: 'center'
            }}
          >
            <img
              style={{
                display: 'block'
              }}
              className={classes.img}
              src={currentPic.src}
              alt={currentPic.jpg}
            />
          </Box>
        </PopupWin>
      </Hidden>
    </Box>
  );
};
