import { useState, useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const useElementSize = (boxRef, cacheTime = 0) => {
  const [elementSize, setElementSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    const handleResize = () => {
      if (boxRef && boxRef.current) {
        setElementSize({
          width: boxRef.current.clientWidth,
          height: boxRef.current.clientHeight,
          elementWidth: boxRef.current.clientWidth,
          elementHeight: boxRef.current.clientHeight,
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
          domWidth: document.documentElement.clientWidth,
          domHeight: document.documentElement.clientHeight
        });
      }
    };
    fromEvent(window, 'resize')
      .pipe(debounceTime(cacheTime))
      .subscribe(handleResize);
    handleResize();
  }, []);
  return elementSize;
};

export default useElementSize;
