import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    img: {
      PointerEvent: 'none',
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    frame: {
      backgroundColor: '#ddc',
      border: 'solid 5vmin #eee',
      borderBottomColor: '#fff',
      borderLeftColor: '#eee',
      borderRadius: '2px',
      borderRightColor: '#eee',
      borderTopColor: '#ddd',
      boxShadow: '0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25)',
      boxSizing: 'border-box',
      '&:before': {
        borderRadius: '2px',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.25) inset',
        content: '',
        left: '-2vmin',
        position: 'absolute',
        right: '-2vmin',
        top: '-2vmin'
      },
      '&:after': {
        borderRadius: '2px',
        bottom: '-2.5vmin',
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.25)',
        content: '',
        left: '-2.5vmin',
        position: 'absolute',
        right: '-2.5vmin',
        top: '-2.5vmin'
      }
    },
    '.picFrame': {
      backgroundColor: '#ddc',
      border: 'solid 30px #eee',
      borderBottomColor: '#fff',
      borderLeftColor: '#eee',
      borderRadius: '2px',
      borderRightColor: '#eee',
      borderTopColor: '#ddd',
      boxShadow: '0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    },
    '.picFrameD': {
      backgroundColor: '#000000',
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
