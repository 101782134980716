import { ajax, AjaxError } from 'rxjs/ajax';
import {
  of,
  Subject,
  from
} from 'rxjs';
import { map, catchError, concatAll } from 'rxjs/operators';
import { gql } from '@apollo/client';

export const GET_INDEXPAGE = gql`
  query indexpage($pageId: ID!) {
    page(id: $pageId, idType: DATABASE_ID) {
      content
    }
  }
`;
export const GET_ATTACHMENT = gql`
  query attachment($pageId: ID!) {
    mediaItem(id: $pageId, idType: DATABASE_ID) {
      id
      databaseId
      gallery {
        picdescription
        picorginalprice
        picprintprice
        picrentprice
        picsize
        pictitle
        picstatus
        picstory
      }
      sourceUrl
    }
  }
`;
export const REC_VISIT = gql`
  mutation RootMutation($val: Int) {
    recordVisit(pageDbId: $val)
  }
`;
const { origin: href } = window.location;
const serviceGloable = {
  debugg: true,
  apiUrl: process.env.NODE_ENV === 'production' ? href : 'http://192.168.2.7',
  httpVal: process.env.NODE_ENV === 'production' ? 'https:' : 'http:',
  apiPort: process.env.NODE_ENV === 'production' ? '80' : '80',
  prefixUrl: process.env.NODE_ENV === 'production' ? '/wp-content/themes/mcluhan' : '',
  user: null,
  token: null,
  keyToken: '_token_',
  socket: null,
  stompClient: null,
  tryTimes: 50,
  uploadPerChunk: 1024 * 1024,
  moveBlock: { counter: 0 },
  alertPop() {
  },
  mute(...all) {
    const i = 0;
    if (i > 1) {
      console.log(all);
    }
  },
  counterWebSocketConnect: 0,
  subjectMsgTest: new Subject(),
  globalEvent: new Subject(),
  dragDropEvent: new Subject(),
  messageReceive(ret) {
    this.subjectMsgTest.next(ret.body);
  },
  messageRegister(registrations) {
    this.stompClient.connect({ token: this.getLocalToken() }, () => {
      registrations.forEach((element) => {
        this.stompClient.subscribe(element.route, element.callback);
      }, (error) => {
        this.log(`connect error : ${error}`);
      });
    });
  },
  initialGlobalVariables() {
    this.user = null;
    this.token = null;
    this.counterWebSocketConnect = 0;
    this.deleteLocalToken();
  },
  setLocalToken(token) {
    this.token = token;
    localStorage.setItem(this.keyToken, token);
  },
  getLocalToken() {
    const token = this.token ? this.token : localStorage.getItem(this.keyToken);
    return token;
  },
  deleteLocalToken() {
    this.token = null;
    localStorage.removeItem(this.keyToken);
  },
  emptyFun() {
    // using emptyFun to avoid the EsLint's "used" syntax check
  },
  log(msg, lev = 'info') {
    if (lev === null) return;
    if (lev === 'info') {
      if (this.debugg) console.log(msg);
    } else {
      console.error(msg);
    }
  },
  /**
   * fetch and check the token in the local storage;
   * if it is empty the return false;
   * if it is not empty then check with the the back-end if it is available;
   */
  checkLocalToken() {
    const preToken = this.getLocalToken();
    if (preToken) {
      return this.get('checkToken', { token: preToken }).pipe(map((ret) => {
        const { flag = false } = ret;
        if (!flag) {
          this.initialGlobalVariables(null);
        } else {
          const retToken = ret.obj.token;
          this.user = ret.obj;
          this.setLocalToken(retToken);
        }
        return { success: flag };
      }));
    }
    return of({ success: false });
  },
  getServerUrl(serverName, url) {
    let ret;
    switch (serverName) {
      case 'ctoq':
        ret = `${this.ctoqUrl}:${this.ctoqPort}/`;
        break;
      default:
        ret = `${this.apiUrl}:${this.apiPort}/`;
    }
    ret += url;
    return ret;
  },
  assembleTargetApiUrl(url, serverName) {
    let ret;
    let varServerName = serverName;
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      ret = url;
    } else {
      if (!varServerName) {
        varServerName = 'default';
      }
      ret = this.getServerUrl(varServerName, url);
    }
    return ret;
  },
  assembleHeader() {
    const header = {
      'Finger-Print': this.token
    };
    return header;
  },
  get(url, paramObj = null, header) {
    let varUrl = url;
    if (paramObj !== null) {
      const affix = Object.keys(paramObj).map(
        (key) => `${key}=${encodeURIComponent(paramObj[key])}`
      ).join('&');
      varUrl += `?${affix}`;
    }
    this.mute(header);
    return this.sendRequest(varUrl);
  },
  post(url, body = '') {
    return this.sendRequest(url, 'POST', body);
  },
  put(url, body = '') {
    return this.sendRequest(url, 'PUT', body);
  },
  patch(url, body = '') {
    return this.sendRequest(url, 'PATCH', body);
  },
  delete(url, body = '') {
    return this.sendRequest(url, 'DELETE', body);
  },
  sendRequest(url, methodValue = 'GET', bodyValue = '', sendHeaders) {
    let varSendHeaders = sendHeaders;
    if (!varSendHeaders && methodValue !== 'GET') {
      varSendHeaders = this.assembleHeader();
    }
    return ajax({
      url: this.assembleTargetApiUrl(url),
      method: methodValue,
      headers: varSendHeaders,
      body: bodyValue
    }).pipe(
      map((response) => response.response),
      map((response) => response),
      catchError((error) => {
        const retObj = this.handleRequestError(error);
        return of(retObj);
      })
    );
  },
  handleRequestError(error) {
    const retObj = { success: false };
    if (error instanceof AjaxError) {
      const { status, response } = error;
      switch (status) {
        case 401:
          retObj.message = response ? response.message : 'Server error';
          break;
        case 0:
          retObj.message = 'No server response';
          break;
        case 404:
          retObj.message = response.error;
          break;
        case 500:
          retObj.message = `${response.error}\r\n${response.message}`;
          break;
        case 403:
          retObj.message = 'Forbidden';
          break;
        case 503:
          retObj.message = 'Service unavailable';
          break;
        default:
          retObj.message = 'Unknown error';
      }
    }
    return retObj;
  },
  uploadFileSend(fileName, iCurrentChunk, iTotalChunk, chunkBlob, fileToken) {
    const metaObject = {};
    metaObject.fileName = fileName;
    metaObject.fileToken = fileToken;
    metaObject.iCurrentChunk = iCurrentChunk;
    metaObject.iTotalChunk = iTotalChunk;
    return this.assembleBlob(metaObject, chunkBlob).pipe(map((blob) => {
      const headers = this.assembleHeader();
      headers['Content-Type'] = 'application/octet-stream';
      return this.sendRequest('uploadFile',
        'PUT',
        blob,
        headers);
    }),
    concatAll());
  },
  uploadFileNext(iCurrentChunk, iTotalChunk, file, token, cb) {
    const iOffSet = iCurrentChunk * this.uploadPerChunk;
    const readChunkSize = Math.min(this.uploadPerChunk, file.size - iOffSet);
    const chunkBlob = file.slice(iOffSet, iOffSet + readChunkSize);
    if (iCurrentChunk < iTotalChunk) {
      this.uploadFileSend(file.name,
        iCurrentChunk,
        iTotalChunk,
        chunkBlob,
        token)
        .subscribe((retObj) => {
          const fileToken = retObj.obj;
          const nextChunk = iCurrentChunk + 1;
          cb(nextChunk, iTotalChunk);
          this.uploadFileNext(nextChunk,
            iTotalChunk, file, fileToken, cb);
        });
    }
  },
  uploadFile(file, cb) {
    const iTotalChunk = Math.ceil(file.size / this.uploadPerChunk);
    const iCurrentChunk = 0;
    this.uploadFileNext(iCurrentChunk, iTotalChunk, file, null, cb);
  },
  assembleBlob(metaInfo, blob) {
    const strMetaInfo = JSON.stringify(metaInfo);
    const iMetaInfoLen = strMetaInfo.length;
    const bufMetaInfoLen = new ArrayBuffer(4);
    const arrayMetaInfoLen = new DataView(bufMetaInfoLen);
    arrayMetaInfoLen.setUint32(0, iMetaInfoLen);

    const bufMetaInfoContent = new ArrayBuffer(iMetaInfoLen);
    const arrayMetaInfoContent = new Uint8Array(bufMetaInfoContent);
    for (let i = 0; i < iMetaInfoLen; i++) {
      arrayMetaInfoContent[i] = strMetaInfo.charCodeAt(i);
    }
    return from(blob.arrayBuffer()).pipe(map((bufBlob) => {
      const totalBufLen = bufMetaInfoLen.byteLength
                          + bufMetaInfoContent.byteLength
                          + bufBlob.byteLength;
      const totalBufferArray = new Uint8Array(totalBufLen);
      totalBufferArray.set(new Uint8Array(bufMetaInfoLen), 0);
      totalBufferArray.set(new Uint8Array(bufMetaInfoContent), bufMetaInfoLen.byteLength);
      totalBufferArray.set(new Uint8Array(bufBlob),
        bufMetaInfoLen.byteLength + bufMetaInfoContent.byteLength);
      return totalBufferArray;
    }));
  },
  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  },
  addNSecondsDelay(n) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, n * 1000);
    });
  },
  analyzeIdx(parIdx) {
    let idx = parIdx;
    if (!idx || idx.length === 0) {
      idx = '-1_-1_none_nonen_-1_-1';
    }
    const [all, position, status, section, storyId, tranId] = idx.match(/(-?\d+)_-?\d+_([\w ]+)_(\w+)_(-?\d+)_(-?\d+)/);
    return {
      all,
      position: position * 1,
      status,
      section,
      storyId: storyId * 1,
      tranId: tranId * 1
    };
  },
  parseJson(parItem) {
    let item = parItem;
    item = typeof item !== 'string'
      ? JSON.stringify(item)
      : item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return null;
    }
    if (typeof item === 'object') {
      return item;
    }
    return null;
  }
};
export default serviceGloable;
