import {
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import sg, { GET_INDEXPAGE } from '../../serviceGlobal';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Activity = () => {
  const classes = useStyles();
  const [imgLst, setImgLst] = useState([]);
  const ref = useRef(null);
  const client = useApolloClient();
  sg.mute('');
  useEffect(() => {
    client.query({ query: GET_INDEXPAGE, variables: { pageId: 105 } }).then((retObj) => {
      if (retObj.data?.page) {
        const { content } = retObj.data.page;
        const imgArray = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/ig);
        const lst = [];
        if (imgArray) {
          imgArray.forEach((element, index) => {
            const src = element.replace(/<img.*?src=.*?["'](.*?)["'].*?\/>/, '$1');
            lst.push({
              src,
              idx: index
            });
          });
        }
        setImgLst(lst);
      }
    });
    /*
    const paramObj = {
      json: 'get_page',
      id: 105,
      post_type: 'page'
    };
    sg.get(sg.apiUrl, paramObj).subscribe((retObj) => {
      if (retObj.page) {
        const { content } = retObj.page;
        const imgArray = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/ig);
        const lst = [];
        if (imgArray) {
          imgArray.forEach((element, index) => {
            const src = element.replace(/<img.*?src=.*?["'](.*?)["'].*?\/>/, '$1');
            lst.push({
              src,
              idx: index
            });
          });
        }
        setImgLst(lst);
      }
    });
    */
  }, []);
  return (
    <Box
      ref={ref}
      className={classes.root}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}
    >
      <Grid
        container
        spacing={3}
      >
        {imgLst.map((img) => (
          <Grid
            item
            key={img.idx}
            xl={4}
            lg={6}
            md={6}
            xs={12}
          >
            <Box>
              <img
                src={img.src}
                alt=""
                style={{
                  width: '100%'
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Activity;
