import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'ragb(255,255,255,0.9',
    minHeight: '80vh'
  },
  '& .MuDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="colse"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const PopupWin = ({
  dialogTitle = 'No title',
  children,
  open,
  onClose
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      fullScreen={fullScreen}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={onClose}
      >
        {dialogTitle}
      </BootstrapDialogTitle>
      <DialogContent
        dividers
        sx={{
          m: 0,
          p: 0
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
PopupWin.propTypes = {
  dialogTitle: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default PopupWin;
