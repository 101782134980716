import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import PageNavbar from './PageNavbar';
import PageSidebar from './PageSidebar';

const PageLayoutRoot = experimentalStyled('div')(
  () => ({
    backgroundImage: 'linear-gradient(#eee, #aaa)',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const PageLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  })
);

const PageLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const PageLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const PageLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <PageLayoutRoot>
      <PageNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <PageSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <PageLayoutWrapper>
        <PageLayoutContainer>
          <PageLayoutContent>
            <Outlet />
          </PageLayoutContent>
        </PageLayoutContainer>
      </PageLayoutWrapper>
    </PageLayoutRoot>
  );
};

export default PageLayout;
