import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import sg, { GET_INDEXPAGE } from '../../serviceGlobal';

const createData = (
  idx,
  size,
  kind,
  one,
  two,
  memo
) => ({
  idx,
  size,
  kind,
  one,
  two,
  memo
});

const rows = [
  createData(1, 'A4', 'tekening', '45 euro', '80 euro', 'zonder lijst'),
  createData(2, 'A3', 'tekening', '90 euro', '120 euro', 'zonder lijst'),
  createData(3, '24*30CM', 'olieverf', '250 euro', '350 euro', 'met lijst'),
  createData(4, '30*40CM', 'olieverf', '350 euro', '450 euro', 'met lijst'),
  createData(5, '40*50CM', 'olieverf', '600 euro', '700 euro', 'met lijst'),
  createData(6, '50*60CM', 'olieverf', '700 euro', '800 euro', 'met lijst'),
  createData(7, '50*70CM', 'olieverf', '800 euro', '900 euro', 'met lijst'),
  createData(8, '60*80CM', 'olieverf', '900 euro', '1050 euro', 'met lijst'),
  createData(9, '40*50CM', 'olieverf(zwart en wit)', '350 euro', '', 'zonder lijst'),
  createData(10, '24*30CM', 'olieverf(zwart en wit)', '100 euro', '', 'zonder lijst')
];
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const Contact = () => {
  const classes = useStyles();
  const [imgLst, setImgLst] = useState([]);
  const ref = useRef(null);
  const client = useApolloClient();
  sg.mute(createData);
  useEffect(() => {
    client.query({ query: GET_INDEXPAGE, variables: { pageId: 281 } }).then((retObj) => {
      if (retObj.data?.page) {
        const { content } = retObj.data.page;
        const imgArray = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/><figcaption>(.*?)<\/figcaption>/ig);
        const lst = [];
        if (imgArray) {
          imgArray.forEach((element, index) => {
            const objMatch = element.match(/<img.*?src=.*?["'](.*?)["'].*?\/><figcaption>(.*?)<\/figcaption>/);
            const [whole, src, caption] = objMatch;
            lst.push({
              idx: index,
              src,
              caption: caption.replace(/<br>/ig, ''),
            });
            sg.mute(whole);
          });
        }
        setImgLst(lst);
      }
    });
    /*
    const paramObj = {
      json: 'get_page',
      id: 281,
      post_type: 'page'
    };
    sg.get(sg.apiUrl, paramObj).subscribe((retObj) => {
      if (retObj.page) {
        const { content } = retObj.page;
        const imgArray = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/><figcaption>(.*?)<\/figcaption>/ig);
        const lst = [];
        if (imgArray) {
          imgArray.forEach((element, index) => {
            const objMatch = element.match(/<img.*?src=.*?["'](.*?)["'].*?\/><figcaption>(.*?)<\/figcaption>/);
            const [whole, src, caption] = objMatch;
            lst.push({
              idx: index,
              src,
              caption: caption.replace(/<br>/ig, ''),
            });
            sg.mute(whole);
          });
        }
        setImgLst(lst);
      }
    });
    */
  }, []);
  return (
    <Box
      ref={ref}
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start'
      }}
    >
      <Box
        sx={{
          background: 'rgba(0,0,0, 0.1)',
          border: '1px solid rgba(255,255,255,0.8)',
          flex: '0 1 auto',
          textAlign: 'center',
          padding: 2
        }}
      >
        <Typography>
          Commission
        </Typography>
      </Box>
      <Box>
        <Grid
          container
          spacing={3}
          alignItems="strecth"
        >
          {imgLst.map((img) => (
            <Grid
              item
              key={img.idx}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  display: 'block',
                }}
              >
                <img
                  src={img.src}
                  alt=""
                  style={{
                    width: '100%'
                  }}
                />
                <Typography
                  sx={{
                    background: 'rgba(0,0,0,0.3)',
                    color: 'white',
                    position: 'absolute',
                    p: '2px',
                    right: 0,
                    left: 0,
                    bottom: 0
                  }}
                >
                  {img.caption}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <TableContainer
        cmponent={Paper}
        sx={{
          border: '1px solid rgba(255,255,255,0.8)',
          borderRadius: '2px',
          width: '100%'
        }}
      >
        <Table
          sx={{
            minWdith: 650,
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                background: 'rgba(0, 0, 0, 0.1)'
              }}
            >
              <TableCell>
                Size
              </TableCell>
              <TableCell>
                Sort
              </TableCell>
              <TableCell>
                One Person
              </TableCell>
              <TableCell>
                Two Person
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.idx}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 }
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.size}
                </TableCell>
                <TableCell>
                  {row.kind}
                </TableCell>
                <TableCell>
                  {row.one}
                </TableCell>
                <TableCell>
                  {row.two}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="h5"
        sx={{
          mt: 1
        }}
      >
        Andere formaten is mogenlijk
      </Typography>
    </Box>
  );
};

export default Contact;
