import sg from '../serviceGlobal';

const Ylogo = (props) => (
  <img
    alt="Logo"
    src={`${sg.prefixUrl}/static/ylogo.png`}
    {...props}
  />
);

export default Ylogo;
