import {
  Box,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import useElementSize from 'src/hooks/useElementSize';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import { useApolloClient } from '@apollo/client';
import sg, { GET_INDEXPAGE } from '../../serviceGlobal';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const About = () => {
  const classes = useStyles();
  const [about, setAbout] = useState(null);
  const ref = useRef(null);
  const boxSize = useElementSize(ref);
  const client = useApolloClient();
  sg.mute('');
  useEffect(() => {
    client.query({ query: GET_INDEXPAGE, variables: { pageId: 112 } }).then((retObj) => {
      if (retObj.data?.page) {
        const { content } = retObj.data.page;
        const img = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/i);
        const [whole, src] = img;
        sg.mute(whole, src);
        const txtArray = content.match(/<p.*?>.*?<\/p>/ig);
        const txtLines = [];
        if (txtArray) {
          txtArray.forEach((element) => {
            const txt = element.replace(/<p.*?>(.*)<\/p>/, '$1');
            if (txt && txt.length > 1) {
              txtLines.push(txt.trim());
            }
          });
        }
        const txtContent = txtLines.length > 0 ? txtLines.join('\n') : '';
        setAbout({
          src,
          txtContent
        });
      }
    });
    /*
    const paramObj = {
      json: 'get_page',
      id: 112,
      post_type: 'page'
    };
    sg.get(sg.apiUrl, paramObj).subscribe((retObj) => {
      if (retObj.page) {
        const { content } = retObj.page;
        const img = content.match(/<img.*?src=.*?["'](.*?)["'].*?\/>/i);
        const [whole, src] = img;
        sg.mute(whole, src);
        const txtArray = content.match(/<p.*?>.*?<\/p>/ig);
        const txtLines = [];
        if (txtArray) {
          txtArray.forEach((element) => {
            const txt = element.replace(/<p.*?>(.*)<\/p>/, '$1');
            if (txt && txt.length > 1) {
              txtLines.push(txt.trim());
            }
          });
        }
        const txtContent = txtLines.length > 0 ? txtLines.join('\n') : '';
        setAbout({
          src,
          txtContent
        });
      }
      */
  }, []);
  return (
    <Box
      ref={ref}
      className={classes.root}
    >
      {about && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: boxSize.width < 1200 ? 'center' : 'start'
          }}
        >
          <Box
            sx={{
              pr: 3,
              maxWidth: '500'
            }}
          >
            <img
              className="picFrame"
              src={about.src}
              style={{
                width: '100%'
              }}
              alt=""
            />
          </Box>
          <Box
            sx={{
              maxWidth: '500px',
              flex: '1 1 auto'
            }}
          >
            <Typography
              sx={{
                whiteSpace: 'pre-line',
                width: '100%',
                wordWrap: 'break-word'
              }}
            >
              {about.txtContent}
            </Typography>
            <List
              sx={{
                ml: '-2px',
                '& li': {
                  pl: 0
                },
                '& li>:first-of-type': {
                  mr: '10px'
                }
              }}
            >
              <ListItem>
                <PhoneIphoneIcon />
                +31626928302 (liefste whatsapp)
              </ListItem>
              <ListItem>
                <InstagramIcon />
                yo5996
              </ListItem>
              <ListItem>
                <EmailIcon />
                chenyouzan1982@gmail.com
              </ListItem>
              <ListItem>
                <HomeIcon />
                Doetinchem de huet
              </ListItem>
            </List>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default About;
