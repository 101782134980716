import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
// import sg from './serviceGlobal';

const client = new ApolloClient({
  /*
  link: new HttpLink({
    // uri: `${sg.apiUrl}/?graphql`,
    uri: 'https://www.yoyoart.nl/?graphql',
    fetchOptions: {
      mode: 'no-cors'
    }
  }),
  */
  uri: 'https://www.yoyoart.nl/?graphql',
  cache: new InMemoryCache(),
});

const App = () => {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ApolloProvider client={client}>
        {routing}
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
