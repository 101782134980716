import { Navigate } from 'react-router-dom';
import NotFound from 'src/pages/NotFound';
import PageLayout from './components/PageLayout';
import Cms from './components/cms';
import Detail from './components/cms/Detail';
import About from './components/cms/About';
import Activity from './components/cms/Activity';
import Contact from './components/cms/Contact';
import Workshop from './components/cms/Workshop';

const routes = [
  {
    path: 'page',
    element: <PageLayout />,
    children: [
      { path: '/', element: <Cms /> },
      { path: '/:idx', element: <Cms /> },
      { path: '/detail/:pageIdx/:idx', element: <Detail /> },
    ]
  },
  {
    path: '/',
    element: <PageLayout />,
    children: [
      { path: '/about', element: <About /> },
      { path: '/activity', element: <Activity /> },
      { path: '/contact', element: <Contact /> },
      { path: '/workshop', element: <Workshop /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/page" /> },
      { path: '*', element: <Navigate to="404" /> }
    ]
  }
];

export default routes;
