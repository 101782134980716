import { keyframes } from '@emotion/react';
import {
  Box,
  makeStyles,
  Typography
} from '@material-ui/core';
import AutorenewTwoToneIcon from '@material-ui/icons/AutorenewTwoTone';
import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import useElementSize from '../../hooks/useElementSize';
import sg, { GET_ATTACHMENT, REC_VISIT } from '../../serviceGlobal';

const loadingSpin = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
  },
  img: {
    cursor: 'pointer'
  }
}));

const LoadingBlock = ({ pageIdx }) => {
  const navigate = useNavigate();
  sg.mute(pageIdx);
  const returnPage = () => {
    navigate(`/page/${pageIdx}`);
  };
  sg.mute(navigate);
  return (
    <Box
      onClick={returnPage}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Box>
        <AutorenewTwoToneIcon
          sx={{
            fontSize: 60,
            color: 'rgba(0,0,0,0.3)',
            animation: `${loadingSpin} 1.5s linear infinite`
          }}
        />
      </Box>
    </Box>
  );
};
LoadingBlock.propTypes = {
  pageIdx: PropTypes.string
};
const PicBlock = ({ imgBox }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  sg.mute(imgBox, navigate);
  const info = imgBox.info.filter((obj) => obj.value !== '');
  const returnPage = () => {
    navigate(`/page/${imgBox.pageIdx}`);
  };
  const calculateLength = (imgB, infoA) => {
    let len = 0;
    if (infoA.length < 1) {
      return len;
    }
    const arr = info.map((it) => Math.max(it.label.length, it.value.length));
    const max = arr.reduce((a, b) => Math.max(a, b), -Infinity);
    len = Math.min(max * 16, imgB.actualImgWithFrameWidth);
    return len;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        alignContent: 'start',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      <Box
        className="picFrame"
        onClick={returnPage}
        sx={{
          width: imgBox.actualImgWithFrameWidth,
          p: 0,
          mb: '20px'
        }}
      >
        <img
          style={{
            width: imgBox.actualImgWidth,
            maring: 0,
            display: 'block',
            padding: 0
          }}
          className={classes.img}
          onContextMenu={(e) => e.preventDefault()}
          src={imgBox.src}
          alt=""
        />
      </Box>
      <Box
        sx={{
          flex: `0 1 ${calculateLength(imgBox, info)}px`
        }}
      >
        {info.map((data) => (
          <Box
            key={data.label}
          >
            <Typography
              variant="h5"
            >
              {data.label}
            </Typography>
            <Typography
              sx={{
                whiteSpace: 'pre-line'
              }}
            >
              {data.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
PicBlock.propTypes = {
  imgBox: PropTypes.object
};
const Detail = () => {
  const classes = useStyles();
  const routeParams = useParams();
  const [originalImg, setOriginalImg] = useState(null);
  const [imgBox, setImgBox] = useState(null);
  const [visitTimes, setVisitTimes] = useState('');
  const { idx = 0, pageIdx = 79 } = routeParams;
  const ref = useRef(null);
  const boxSize = useElementSize(ref);
  const client = useApolloClient();
  const imgLoaded = (img, extra) => {
    const info = [];
    if (extra) {
      Object.values(extra).forEach((obj) => {
        const { label, menu_order: order, value } = obj;
        if (value) {
          if (!(label === 'Status' && value === 'None')) {
            info.push({
              label,
              order,
              value
            });
          }
        }
      });
    }
    info.sort((a, b) => a.order - b.order);
    const { naturalWidth: width, naturalHeight: height } = img;
    setOriginalImg({
      width,
      height,
      src: img.src,
      info
    });
  };
  useEffect(() => {
    if (boxSize && originalImg) {
      const ratio = originalImg.height / originalImg.width;
      const withSide = boxSize.elementWidth >= 1600;
      const availableImgAreaWidth = withSide ? boxSize.elementWidth - 256 : boxSize.elementWidth;
      const minImgTextWidth = 250;
      const availablePureImgWidth = availableImgAreaWidth - minImgTextWidth - 30 - 30;
      let actualImgWidth = availablePureImgWidth >= originalImg.width
        ? originalImg.width : availablePureImgWidth;
      actualImgWidth = actualImgWidth + 60 + minImgTextWidth > 200
        ? Math.min(boxSize.width - 60, originalImg.width) : actualImgWidth;
      const ratioHeigth = actualImgWidth * ratio;
      if (ratioHeigth > boxSize.height) {
        actualImgWidth = (boxSize.height - 90) * (originalImg.width / originalImg.height);
      }
      actualImgWidth -= 20;
      const actualImgWithFrameWidth = actualImgWidth + 30 + 30;
      const actualImgTextWidth = availableImgAreaWidth - actualImgWithFrameWidth;
      setImgBox({
        withSide,
        availableImgAreaWidth,
        actualImgWidth,
        actualImgWithFrameWidth,
        minImgTextWidth,
        actualImgTextWidth,
        info: originalImg.info,
        pageIdx,
        src: originalImg.src
      });
    }
  }, [boxSize, originalImg]);
  useEffect(() => {
    client.query({ query: GET_ATTACHMENT, variables: { pageId: idx } }).then((retObj) => {
      if (retObj.data?.mediaItem) {
        const { sourceUrl, gallery } = retObj.data.mediaItem;
        if (sourceUrl) {
          const imgUrl = sourceUrl;
          const img = new Image();
          const {
            picdescription,
            picorginalprice,
            picprintprice,
            picrentprice,
            picsize,
            picstatus,
            pictitle,
            picstory,
          } = gallery;
          const extra = {
            b: {
              value: picdescription,
              menu_order: 2,
              label: 'Descritpion'
            },
            e: {
              value: picorginalprice,
              menu_order: 5,
              label: 'Price'
            },
            f: {
              value: picprintprice,
              menu_order: 6,
              label: 'Print'
            },
            g: {
              value: picrentprice,
              menu_order: 7,
              label: 'Rent'
            },
            c: {
              value: picsize,
              menu_order: 3,
              label: 'Size'
            },
            d: {
              value: picstatus,
              menu_order: 4,
              label: 'Status'
            },
            a: {
              value: pictitle,
              menu_order: 1,
              label: 'Title'
            },
            h: {
              value: picstory,
              menu_order: 8,
              label: 'Verhaal'
            }
          };
          img.onload = () => imgLoaded(img, extra);
          const src = imgUrl.replace(/(\/[A-Za-z-]+0?)1(\.)/, '$12$2');
          img.src = src;
        }
      }
    });

    client.mutate({
      mutation: REC_VISIT,
      variables: {
        val: idx * 1,
      },
    }).then((ret) => {
      const times = ret.data?.recordVisit;
      setVisitTimes(times);
    });
    /*
    const paramObj = {
      json: 'get_page',
      id: idx,
      post_type: 'attachment'
    };
    sg.get(sg.apiUrl, paramObj).subscribe((retObj) => {
      if (retObj.page) {
        const { content } = retObj.page;
        const imgMatchObj = content.match(/<a.*?href=["'](.*?)["']/i);
        if (imgMatchObj) {
          const [whole, imgUrl] = imgMatchObj;
          const img = new Image();
          img.onload = () => imgLoaded(img, retObj.extra);
          const src = imgUrl.replace(/(\/[A-Za-z-]+0?)1(\.)/, '$12$2');
          img.src = src;
          sg.mute(whole);
        }
      }
    });
    */
  }, []);
  return (
    <Box
      className={classes.root}
      ref={ref}
    >
      <Box
        sx={{
          position: 'fixed',
          bottom: 15,
          zIndex: 10000,
          left: 15,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            }
          },
          animation: 'fadeIn 5s ease',
        }}
      >
        <Typography
          sx={{
            borderRadius: '50%',
            pl: 0.5,
            pr: 0.5,
            border: {
              xl: 'none',
              xs: `1px solid ${grey.A200}`,
            },
            textShadow: '2px 2px 4px #000000',
            color: grey[100]
          }}
        >
          {visitTimes}
        </Typography>
      </Box>
      {
        imgBox ? <PicBlock imgBox={imgBox} /> : <LoadingBlock pageIdx={pageIdx} />
      }
    </Box>
  );
};

export default Detail;
