import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import sg from '../serviceGlobal';
import Logo from './Ylogo';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  bar: {
    backgroundImage: 'linear-gradient(#eee, #aaa)',
  }
}));
const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  sg.mute(classes);
  const a = 1;
  return (
    <AppBar
      elevation={0}
      {...rest}
      className={classes.bar}
    >
      <Toolbar>
        <RouterLink to="/page">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: a }} />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
