import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core';
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import SchoolIcon from '@material-ui/icons/School';
import PaletteTwoToneIcon from '@material-ui/icons/PaletteTwoTone';
import PermMediaTwoToneIcon from '@material-ui/icons/PermMediaTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone';
import AutoAwesomeTwoToneIcon from '@material-ui/icons/AutoAwesomeTwoTone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import InstagramIcon from '@material-ui/icons/Instagram';
import NavItem from './NavItem';
import sg from '../serviceGlobal';

const useStyles = makeStyles((theme) => ({
  floatDrawer: {
    backgroundColor: theme.palette.background.dark
  },
  sideBar: {
    backgroundImage: 'linear-gradient(#eee, #aaa)',
  }
}));
const user = {
  avatar: `${sg.prefixUrl}/static/images/avatars/yoyo.jpg`,
  jobTitle: 'Artist',
  name: 'Yoyo'
};

const items = [
  {
    href: '/about',
    icon: AccountCircleTwoToneIcon,
    title: 'About'
  },
  {
    href: '/page/79',
    icon: PermMediaTwoToneIcon,
    title: 'GALLERY'
  },
  {
    href: '/page/485',
    icon: CreateTwoToneIcon,
    title: 'SKETCH'
  },
  {
    href: '/page/1235',
    icon: DirectionsWalkIcon,
    title: 'KUNSTWANDELROUTE'
  },
  {
    href: '/page/1216',
    icon: SchoolIcon,
    title: 'WERKEN HOUTKAMP'
  },
  {
    href: '/activity',
    icon: AutoAwesomeTwoToneIcon,
    title: 'ACTIVITY'
  },
  {
    href: '/workshop',
    icon: PaletteTwoToneIcon,
    title: 'WORKSHOP'
  },
  {
    href: '/contact',
    icon: LocalAtmTwoToneIcon,
    title: 'PRICE'
  }
];

const PageSidebar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      className={classes.sideBar}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/about"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box>
        <List
          sx={{
            '& li': {
              color: 'rgba(0,0,0,0.4444)'
            },
            '& li>:first-of-type': {
              mr: '10px'
            },
            mb: 5
          }}
        >
          <ListItem>
            <PhoneIphoneIcon />
            +31626928302
          </ListItem>
          <ListItem>
            <InstagramIcon />
            yo5996
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          className={classes.floatDrawer}
          PaperProps={{
            sx: {
              width: 256,
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
              background: 'none'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

PageSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

PageSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default PageSidebar;
